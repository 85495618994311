$dark-background-color:       #2d2e2f !default;
$dark-text-color: #bbb !default;

html[data-theme="dark"]  {
  color-scheme: dark;

  &[data-scroll-status='top'] {
    header.site-header-transparent {
      &.site-header {
        .header-menu__button-icon {
          color: white;
        }

        @include media-query($on-laptop) {
          .page-link {
            color: #f8f8f8;
          }
        }
      }
    }

    footer.site-footer {
      .site-footer-inner {
        border-top: solid 1px #2f2f2f !important;
        transition: 0s;
      }
    }
  }

  svg {
    fill: rgba($dark-text-color, 80%);
  }

  body {
    color: $dark-text-color;
    background-color: $dark-background-color;
  }

  *:not(pre) > code {
    color: $dark-text-color;
    background-color: #454545;
  }

  blockquote {
    border-left: 4px solid #484848;
  }

  table {
    color: #9d9d9d;

    th {
      background-color: #050505;
    }

    tr:nth-child(even) {
      background-color: #080808;
    }
  }

  .site-header {
    background-color: #313233;
    --animation-waiting-time: .5s;
    --animation-delay-multiplier: .07s;
    transition: background-color 0.3s linear,transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);

    .site-brand {
      .site-brand-inner {
        &, &:visited {
          color: #f8f8f8;
        }
      }
    }

    .header__drawer {
      background-color: $dark-background-color;

      .header-menu__primary .page-link {
        color: #f8f8f8;
      }
    }

    .header-menu__button-icon {
      color: white;
    }

    .site-nav .page-link {
      color: #f8f8f8;
    }

    .ct-language-dropdown {
      color: #f8f8f8;
      background-color: $dark-background-color;
      box-shadow: 0 0 3px 1px #0000005b;
    }

    .ct-language-selected, .ct-language-dropdown li:hover {
      background-color: #222 !important;
    }

    @include media-query($on-laptop) {
      .menu-icon > svg {
          fill: rgba($dark-text-color, 80%);
      }

      .site-nav input:checked ~ .trigger {
        background-color: #090909;
      }
    }
  }

  .site-footer {
    color: #fff;
    background-color: #313233;
  }

  .left-vsplit:before {
    background-color: #9a9a9a;
  }

  .page-banner {
    .page-banner-img {
      & > *:first-child {
        opacity: 0.718;
      }
    }
  }

  #blogs {
    .blog-item:first-child {
      background-color: #3a3a3a;
    }

    a {
      color: $dark-text-color;
    }
  }

  #contact {
    .contact-mail {
      a {
        color: $dark-text-color;
      }
    }
  }

  .pagination {
    .post-link {
      color: $dark-text-color;
    }

    .post-title {
      a:visited:after {
        background-color: $dark-background-color;
      }

      a:after {
        color: $dark-background-color;
      }
    }

    .post-list {
      & > li:not(:last-child) {
        border-bottom: 1px solid #545454;
      }
    }

    .post-tags .post-tag:hover {
      color: #d7d7d7;
    }
  }

  .page-segments {
    li {
      a {
        color: #ddd;
      }

      a:visited:after {
        background-color: $dark-background-color;
      }

      a:after {
        color: $dark-background-color;
      }
    }
  }

  .post .post-content {
    img:not([raw]) {
      background-color: #ffffff33;
    }

    aside {
      background: rgb(60, 65, 68);
    }
  }

  .post-related {
    & > *:first-child {
      color: #d7d7d7;
    }

    a:visited:after {
      background-color: $dark-background-color;
    }

    a:after {
      color: $dark-background-color;
    }

    a:hover {
      color: #aaa;
    }
  }

  .common-list {
    li {
      border-bottom: solid 1px #40404088;

      a {
        color: #aaa;
      }

      a:hover {
        background-color: #272727;
      }

      span {
        background-color: #333;
      }
    }
  }

  .post-menu {
    .post-menu-title {
      color: #ddd;
    }

    .post-menu-content {
      ul {
        border-left: 1px solid #787878;

        .active {
          background-color: #2d2d2d;
          border-left: 2px solid #aaa;
        }

        a {
          color: $dark-text-color;
        }

        a:hover {
          color: #fff !important;
        }
      }
    }
  }
}
