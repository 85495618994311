/**
 * Animation for transparent header
 */
html {
  &[data-header-transparent] {
    header.site-header {
      position: fixed;
    }
  }

  &[data-scroll-status='top'] {
    header.site-header-transparent {
      height: 0;
      // margin-top: 12px;
      background-color: transparent;
      transition: 0.1s height,background-color,box-shadow;

      &.site-header {
        .site-brand-inner, .page-link {
          color: #fff;
          transition: 0.1s color;
        }

        .header__inner, .page-link {
          color: #fff;
          transition: 0.1s color;

          .header-menu__button-icon {
            color: white;
          }
        }

        @include media-query($on-laptop) {
          .page-link {
            color: $header-text-color;
          }

          .menu-icon {
            > svg {
              fill: $white-color;
            }
          }
        }
      }
    }

    footer.site-footer {
      color: unset;
      background-color: transparent;

      .site-footer-inner {
        border-top: solid 1px #eee;
      }
    }
  }

  &[data-scroll-status='down'] {
    header.site-header {
      // top: -$header-height;
      transform: translateY(-$header-height);
    }

    .framework .sidebar {
      top: 20px;
    }
  }
}

html.global-disable-scroll {
  height: 100vh;
  overflow: hidden;
  width: 100%;
}

/**
 * Site header
 */
.header--nav-open {
  .header__drawer {
    transform: translateX(0);
    visibility: visible;
  }

  .header-menu__hamburger {
    display: none;
  }

  .header-menu__drawer-close {
    display: block;
  }
}

.header-menu__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  grid-row: 1;
  padding: 0;
}

.header-menu__hamburger {
  display: none;
  position: absolute;
  right: 8px;
  top: 28px;

  @include media-query($on-laptop) {
    display: block;
  }
}

.header-menu__drawer-close {
  display: none;
  position: absolute;
  right: 8px;
  top: 28px;
}

.icon--svg {
  fill: currentColor;
  outline: none;
}

.header-menu__button-icon {
  height: 24px;
  width: 24px;
}

.page-link__icon {
  height: 24px;
  margin-left: 8px;
  transition: transform .3s linear;
  width: 24px;
}

.site-header {
  background-color: $header-background-color;
  height: $header-height;
  width: 100%;
  // transition: height 0.2s, text-shadow 0.2s, top 0.2s;
  --animation-waiting-time: .5s;
  --animation-delay-multiplier: .07s;
  transition: background-color 0.3s linear,transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, .06);

  // Positioning context for the mobile navigation icon
  // @include flex-sticky(0);
  position: fixed;
  z-index: 1000;

  & > .header__wrapper {
    margin: 0 60px;
    padding: 0;
    max-width: 100%;
    transition: 0.2s margin;

    @include media-query(1024px) {
      margin: 0 20px;
      max-width: unset;
    }
  }

  a {
    text-decoration: none;
  }

  .header__inner {
    position: relative;
  }
}

.site-brand {
  line-height: $header-height;
  margin-right: 50px;

  .site-brand-inner {
    color: black;
    font-size: 15.75px;
    font-weight: 400;
    letter-spacing: -1px;
    transition: 0.1s filter color;

    .site-favicon {
      width: 24px;
      height: auto;
    }
  }
}

.site-nav {
  @include relative-font-size(1.125);
  line-height: $header-height;
  position: absolute;
  right: 0;
  top: 0;

  .page-link {
    line-height: $base-line-height;
    color: $header-text-color;
    transition: 0.1s ease-in-out;

    .header-menu__button-icon {
      vertical-align: sub;
      color: $header-text-color;
    }

    svg {
      fill: $header-text-color;
    }

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 64px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include media-query($on-laptop) {
    display: none;
  }
}

.header__drawer {
  display: none;

  background-color: white;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 200px;
  position: absolute;
  right: 0;
  top: $header-height;
  transform: translateX(100%);
  transition: transform .2s ease-in-out, visibility .2s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 20;

  & > .header__drawer-container {
    @include media-query(1024px) {
      margin: 0 20px;
    }
  }

  .header-menu__primary {
    padding: 80px 0 120px;
    border-bottom: 1px solid #c6c6c6;

    .page-link {
      color: $header-text-color;
      transition: 0.1s ease-in-out;
      font-size: 1.8rem;
      font-weight: 700;
    }
  }

  .header-menu__primary-item {
    margin-bottom: 48px;
    margin-left: -16px;
    padding-left: 16px;

    .icon--svg {
      vertical-align: middle;
    }
  }

  .header-menu__language {
    .page-link {
      float: right;
    }
  }

  .ct-language-dropdown {
    top: auto;
    bottom: 110%;
  }

  ul {
    list-style: none;
    margin-left: 0px;
  }

  @include media-query($on-laptop) {
    display: block;
  }
}

/**
 * Site footer
 */
.site-footer {
  @include relative-font-size(1.0);
  color: $footer-text-color;
  background-color: $footer-background-color;
  text-align: left;
  transition: background-color 0.2s;

  .site-footer-inner {
    display: flex;
    justify-content: space-between;

    transition: border-top 0.2s;
    padding: $spacing-unit * 1.8 0;

    .site-footer-text {
      order: 2;
    }

    .theme-toggle-wrapper {
      order: 4;
    }

    @include media-query($on-palm) {
      flex-direction: column;

      .site-footer-text {
        order: 4;
      }

      .theme-toggle-wrapper {
        order: 2;
      }
    }
  }

  a {
    $a-color: $brand-color;
    color: $a-color;

    &:hover {
      color: lighten($a-color, 10%);
    }
  }
}

.copyleft {
  display: inline-block;
  transform: rotate(180deg);
}

/**
 * Post header
 */
%post-header {
  .post-header {
    margin-bottom: $spacing-unit;
  }

  .post-title {
    @include relative-font-size(2.625);
    letter-spacing: -1px;
    line-height: 1.6;

    @include media-query($on-laptop) {
      @include relative-font-size(2.25);
    }
  }

  .post-tags {
    padding-right: 150px;

    .post-tag {
      display: inline-block;
      margin: 0 12px 0 0;
    }
  }
}

/**
 * Page content
 */
.page-content {
  @extend %flex-1; /* <-- Keep footer on the bottom */
  -ms-flex: none; /* <-- Fix IE footer issue */
  padding: $spacing-unit * 2 0;
  padding-top: 72px;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

/**
 * index page
 */
.index-section {
  margin: 0 auto 128px auto;

  .section-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 20px;

    @include media-query($on-palm) {
      flex-flow: column;
    }
  }

  h2.ttl {
    margin-bottom: 40px;
    text-align: center;
    line-height: 1.0;
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    font-size: 40px;
    letter-spacing: 0.05em;
  }

  h3.ttl {
    margin-bottom: 12px;
    text-align: center;
    line-height: 1.0;
    font-size: 22px;
    // color: $text-color;
  }

  p.ttl {
    margin-bottom: 20px;
    text-align: center;
    line-height: 1.0;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.1em;
    // color: $text-color;
  }
}

#service {
  .service-section {
    flex: 1 1 100%;
    margin-bottom: 40px;
  }

  .icon {
    margin-bottom: 20px;
    text-align: center;

    img {
      width: 49px;
      height: 51px;
    }
  }
}

#works {
  .work-wrapper {
    position: relative;
  }

  .works-desc {
    position: absolute;
    width: 480px;
    right: 32px;
    top: 64px;

    @include media-query($on-palm) {
      position: static;
      width: 100%;
    }
  }

  #earth-view {
    align-self: center;
    height: 100%;
    width: 100%;
  }
}

#blogs {
  .blogs-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: stretch;

    @include media-query($on-palm) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
  }

  .blog-item:first-child {
    padding: 40px;
    text-align: center;
    display: flex;
    align-items : center;
    grid-row: 1 / span 2;
    background-color: #F3F3F3;

    .blog-content {
      vertical-align: middle;
    }
  }

  .blog-item {
    padding: 20px;
    margin-top: 10px;

    .blog-link {
      display: block;
      text-decoration: none;
      -webkit-transition: .4s cubic-bezier(.77,0,.175,1);
      transition: .4s cubic-bezier(.77,0,.175,1);
    }

    .blog-content {
      -webkit-transition: .4s cubic-bezier(.77,0,.175,1);
      transition: .4s cubic-bezier(.77,0,.175,1);
      position: relative;
      overflow: hidden;
      margin-top: 10px;
    }

    .blog-content__headline {
      margin: 0 0 .2em;
    }

    .blog-content__tag {
      margin: 0 0 .2em;
    }

    .blog-content__read-more {
      color: #87c6bd;

      opacity: 0;
      -webkit-transition: .4s cubic-bezier(.77,0,.175,1);
      transition: .4s cubic-bezier(.77,0,.175,1);

      @include media-query($on-laptop) {
        opacity: 1;
      }
    }

    a:hover {
      text-decoration: none;

      .blog-content {
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
      }

      .blog-content__read-more {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
      }
    }

    a {
      color: $text-color;
    }

    p {
      line-height: 1.85rem;
    }
  }
}

#contact {
  .contact-mail {
    a {
      text-align: center;
      font-size: 1.5em;
      color: $text-color;
    }
  }
}


/**
 * Pagination page
 */
.pagination {
  .post-list {
    margin-left: 0;
    list-style: none;

    > li {
      margin-bottom: $spacing-unit * 1.5;
      padding-bottom: 30px;

      &:not(:last-child) {
        border-bottom: 1px solid #e3e3e3;
      }
    }
  }

  .post-title {
    margin-bottom: $spacing-unit * 0.2;
    transition: 0.2s all;

    a {
      text-decoration: none;

      &:after {
        content: 'NEW';
        position: absolute;
        margin-left: 8px;
        margin-top: 3px;
        padding: 0px 3px;
        background-color: $brand-color;
        color: #fff;
        font-size: 10px;
        font-weight: 600;
        border-radius: 2px;
      }

      &:visited:after {
        background-color: #fff;
      }
    }
  }

  .post-meta {
    font-size: $base-font-size;
    color: $grey-color;
    margin-bottom: $spacing-unit * 0.5;
  }

  .post-link {
    @include relative-font-size(1.65);
    font-weight: $base-font-weight * 1.5;
    color: #333;
  }

  .post-excerpt {
    color: #777;
    word-break: break-word;
    overflow-wrap: break-word;

    .read_more {
      margin-left: 8px;
    }
  }

  .post-tags {
    margin: 0px;
  }

  .post-tags .post-tag {
    display: inline-block;
    text-decoration: none;
    // border: 1px solid;
    padding: 2px 4px;
    border-radius: 2px;
    transition: color 0.2s;
    margin-bottom: 8px;

    &:not(:last-child) {
      // margin-right: 4px;
      &:after {
        content: "/";
        padding-left: 8px;
      }
    }

    &:hover {
      color: #787878;
    }
  }

  .paginator {
    text-align: center;

    & > .previous:before {
      content: ' ';
      border: solid #787878;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      margin-right: 8px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }

    & > .next:after {
      content: ' ';
      border: solid #787878;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      margin-left: 8px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    .previous span, .next span {
      color: #b3b3b3;
    }

    .indicator {
      padding: 0 15px;
    }
  }
}


/**
 * Posts
 */
.post {
  @extend %post-header;

  max-width: 860px;

  .post-header {
    margin: 50px auto 60px;
    padding: 0 0 20px;
    border-bottom: 1px solid #ebebeb;

    .post-title {
      margin-bottom: 6px;
    }

    .post-subtitle {
      font-weight: lighter;
    }

    .post-meta {
      color: #808080;
    }
  }

  .post-content {
    margin-bottom: $spacing-unit;
    overflow-wrap: normal;
    word-wrap: normal;
    word-break: normal;

    h2 {
      @include relative-font-size(2);

      @include media-query($on-laptop) {
        @include relative-font-size(1.75);
      }
    }

    h3 {
      @include relative-font-size(1.625);

      @include media-query($on-laptop) {
        @include relative-font-size(1.375);
      }
    }

    h4 {
      @include relative-font-size(1.25);

      @include media-query($on-laptop) {
        @include relative-font-size(1.125);
      }
    }

    img, svg, iframe {
      margin: 24px auto;
      display: block;
    }

    h2, h3, h4, h5, h6 {
      margin: 60px 0 19px;
    }

    p, hr {
      margin-bottom: 24px;
    }

    hr {
      height: 1px;
      background-color: #ebebeb;
      border: none;
    }

    aside {
      border-radius: 3px;
      background: rgb(241, 241, 239);
      padding: 16px 16px 16px 12px;
    }
  }

  .post-related {
    &>*:first-child {
      @include relative-font-size(1.425);
      color: #333;
      margin-bottom: 14px;
    }

    ul {
      margin-left: 15px;
      .post-link {
        @include relative-font-size(1.075);
      }

      li {
        line-height: 1.6;
        margin-top: 16px;
      }

      a {
        color: #666;

        &:hover {
          color: #333;
        }

        &:after {
          content: 'NEW';
          position: absolute;
          margin-left: 8px;
          margin-top: 3px;
          padding: 0px 3px;
          background-color: $brand-color;
          color: #fff;
          font-size: 10px;
          font-weight: 600;
          border-radius: 2px;
        }

        &:visited:after {
          background-color: #fff;
        }
      }
    }
  }
}

.post-comments {
  padding-top: 25px;
}


/**
 * Posts misc
 */
.post-nav {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 32px 0;
  padding: 32px 0 0;

  a {
    @include relative-font-size(1.125);
    color: #666;
    margin: 0;
  }

  .previous:before {
    content: ' ';
    border: solid #787878;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    margin-right: 8px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .next:after {
    content: ' ';
    border: solid #787878;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    margin-left: 8px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  @include media-query($on-laptop) {
    flex-direction: column;

    a {
      margin: auto;
      padding: 16px 0;
    }
  }
}


/**
 * Archives page
 */
.page-archives {
  .page-archives-list {
    margin-left: 0;
    list-style: none;
  }

  .archives-time {
    @include relative-font-size(1.5);

    &:not(:first-child) {
      margin-top: 18px;
    }
    margin-bottom: 8px;
  }

  .post-meta {
    font-size: $small-font-size;
    color: $grey-color;
  }
}


/**
 * Page banner
 */
.page-banner {
  display: block;
  position: relative;
  height: $banner-height;
  background-color: $banner-background;
  transition: height 0.2s;

  .page-banner-img {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & > *:first-child {
      @include center-image;
      transition: 0.1s all ease-in-out;
    }

    & > video {
      width: 100vw;
      object-fit: cover;
    }

    & > img.img-placeholder {
      display: none;
    }
  }

  .wrapper {
    height: 100%;
  }

  .page-banner-inner {
    @include vertical-center(relative);

    color: $banner-text-color;
    padding: 10px 5px;
    text-shadow: 1px 1px 2px #33333355;

    & > *:first-child {
      margin: 0;

      > :nth-child(1) {
        @include relative-font-size(3.2);
        letter-spacing: -1px;
        margin-bottom: 0.1em;
        font-weight: normal;
        transition: 0.2s all;

        @include media-query($on-palm) {
          @include relative-font-size(1.675);
        }
      }

      > :nth-child(2) {
        font-weight: lighter;
        margin-bottom: 0.8em;
        transition: 0.2s all;

        @include media-query($on-palm) {
          @include relative-font-size(1.175);
        }
      }

      > :last-child {
        margin-bottom: 0;
      }
    }

    .post-subtitle {
      @include relative-font-size(1.525);
      color: #ffffffcc;
      padding-right: 280px;

      @include media-query($on-palm) {
        padding-right: 0;
      }
    }

    .post-meta {
      color: #ffffffcc;
    }

    .left-vsplit:before {
      background: #e3e3e388;
    }

    .post-tags {
      color: #999;
      padding-right: 280px;

      @include media-query($on-palm) {
        padding-right: 0;
      }

      .post-tag {
        @include relative-font-size(1.125);
        display: inline-block;
        text-decoration: none;
        margin: 9px 12px 0 0;
        color: #fff;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @include media-query($on-palm) {
    height: $banner-height / 1.5;
  }
}


/**
 * Layout and sidebar
 */
.framework {
    @extend %flex;

  .main {
    @extend %flex-1;
  }

  .sidebar {
    padding-left: 8px;
    transition: top 0.2s, display 0.2s;

    @include flex-sticky($header-height + 20px);

    @include media-query($on-laptop) {
      display: none;
    }

  }
}


/**
 * Segments page
 */
.page-segments {
  .page-segments-list {
    margin-left: 0;
    list-style: none;
  }

  .segment-name {
    font-weight: $base-font-weight * 1.5;
    margin-bottom: 8px;
    position: relative;

    @include relative-font-size(1.6);

    &:not(:first-child) {
      margin-top: 28px;
    }

    &:hover:before {
      content: '#';
      left: -1em;
      position: absolute;
    }
  }

  .post-meta {
    font-size: $small-font-size;
    color: $grey-color;
  }

  li a {
    &.post-link {
      margin-left: 5px;
    }

    color: #303030;

    &:hover {
      color: #000;
    }

    &:after {
      content: 'NEW';
      position: absolute;
      margin-left: 8px;
      margin-top: 3px;
      padding: 0px 3px;
      background-color: $brand-color;
      color: #fff;
      font-size: 10px;
      font-weight: 600;
      border-radius: 2px;
    }

    &:visited:after {
      background-color: #fff;
    }
  }
}

.left-vsplit:before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 10px;
  margin: 0 10px;
  background-color: #e3e3e3e3;
  vertical-align: baseline;
}
